import React from "react"
import { Seo } from "../../components/seo"
import { ServiceSectionEn } from "../../components/en/ServicesSectionEn"
import { Link } from "@reach/router"

const ProcessEn = () => {
  return (
  
      <section className="container english-page-body">
        <div className="row row-en">
          <div className="en-pages">
            <h1>Job Introduction Process</h1>

            <div className="job-intro-process">
              <ol className="mb-70">
                <li>
                  <h2>1. Inquiry</h2>
                  <p className="text">
                    Please submit your profile using the application form below.
                    We will get back to you within three business days after
                    your inquiry.
                  </p>
                  <Link
                    to="/en/job-form"
                    className="free-consultation-btn mt-30"
                  >
                    <button>Free Consultation</button>
                  </Link>
                </li>
                <li>
                  <h2>2. Counseling</h2>
                  <p className="text">
                    You will have a meeting with a consultant to discuss your
                    career history and goals, and we will assist you in planning
                    your career path. If needed, we can also provide information
                    about locations and living conditions.
                  </p>
                  <ul className="list">
                    <li>
                      Meetings are held during Mexican business hours, Monday to
                      Friday, from 9 AM to 6 PM. If these times are not
                      convenient, please let us know.
                    </li>
                    <li>
                      Please prepare your resume and work history before the
                      meeting. You can download samples and templates if needed.
                    </li>
                    <li>
                      Meeting tools: Zoom, Google Meet, or Skype are available
                      for use.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2>3. Job Information Introduction</h2>
                  <p className="text">
                    Based on your experience and skills, we will provide you
                    with job information that matches your preferences and offer
                    support for your application process.
                  </p>
                </li>
                <li>
                  <h2>4. Applying for Jobs (Document Screening)</h2>
                  <p className="text">
                    Once we find a job that matches your preferences, we will
                    send your resume and work history to the company, and the
                    document screening process will begin. If needed, we will
                    also provide advice on how to prepare your resume and work
                    history before the screening.
                  </p>
                </li>
                <li>
                  <h2>5. Selection and Interviews</h2>
                  <p className="text">
                    After passing the document screening, we will arrange
                    interviews with the company. All communication with the
                    company, including scheduling interviews, will be handled by
                    us. If you have any questions or concerns during the
                    selection process, feel free to consult with us at any time.
                  </p>
                </li>
                <li>
                  <h2>6. Offer and Onboarding</h2>
                  <p className="text">
                    After accepting the job offer, you will handle the direct
                    communication with the company until your start date.
                    However, to ensure a smooth transition, we will continue to
                    provide detailed support throughout the onboarding process.
                  </p>
                </li>
                <li>
                  <h2>7. After Joining</h2>
                  <p className="text">
                    We are also available for any post-employment consultations.
                    If you encounter any issues or need assistance, please feel
                    free to reach out to Quick Global Mexico.
                  </p>
                </li>
              </ol>
              <ServiceSectionEn />
            </div>
          </div>
        </div>
      {
        //<About language={"en"}></About>
      }
      </section>

  )
}

export default ProcessEn


export const Head = () => {  
  return (
    <Seo
      title={`Mexico job vacancies, employment and career change information | Mexico job vacancies, employment and career change information [QUICK GLOBAL MEXICO]`}
      url={`https://www.919mexico.com/en/process`}
      language="en"
    />
  )
}